<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="审批详情"
        :sub-title="this.$Dictionaries.business_type[this.type]"
        @back="() => $router.go(-1)"
      />
    </div>
    <div style="margin-top: 10px">
      <a-spin tip="加载中..." :spinning="spinning">
        <a-form :form="approval_form" v-if="node_current_user.length > 0" style="border: 1px solid rgb(245, 247, 240);margin:10px 0">
          <a-form-item label="审批" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-radio-group v-decorator="['result', {rules: [{required: true, message: '请选择'}]}]" @change="resultChange">
              <a-radio :value="2">通过</a-radio>
              <a-radio :value="3">不通过</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-textarea v-decorator="['remark', {rules: [{required: result==3, message: '请输入'}]}]" style="width: 50%" :auto-size="{ minRows: 3, maxRows: 5 }"/>
          </a-form-item>
          <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" style="text-align: center">
            <a-button @click="doApproval" type="primary">审批</a-button>
          </a-form-item>
        </a-form>
        <a-tabs type="card">
          <a-tab-pane key="1" tab="流程图">
            <div v-if="form">
              <div style="text-align: right;">
                <a-tag color="#1e90ff">
                  已审批
                </a-tag>
                <a-tag color="#ff943e">
                  当前审批
                </a-tag>
                <a-tag color="#909399">
                  待审批
                </a-tag>
              </div>
              <process-diagram-viewer/>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="审批记录">
            <div>
              <a-table
                v-if="false"
                :columns="columns"
                :dataSource="user_logs"
                :pagination="false"
                :loading="false"
              >
                <span slot="serial" slot-scope="text, record, index">
                  {{ index + 1 }}
                </span>
                <span slot="tags" slot-scope="tags">
                  <a-tag
                    :color="tags === 1 ? '#1e90ff' : '#909399'">{{ approval_result_status[tags] }}
                  </a-tag>
                </span>
                <span slot="result" slot-scope="result">
                  <a-tag :color="result === 2 ? '#32CD32' : result===3 ? '#ff0000' : '#909399'">{{ approval_result[result] }}</a-tag>
                </span>
              </a-table>
              <div style="background-color: white" v-if="false">
                <a-card v-for="(item) in user_logs" :key="item.result_node_id" style="margin-top: 30px;">
                  <p style="fontSize: 14px;color: rgba(0, 0, 0, 0.85); margin-top: 16px;fontWeight: 500;border-top: 1px solid #ededed;padding-top: 10px;">
                  </p>
                  <div :title="i.user_nickname" :value="i" v-for="i in item.salesAllList" :key="i.id" style="margin-top: 10px;">
                    <a style="width: 100px; display: inline-block; text-align: left;">{{ i.user_nickname }}</a>
                    <a-tag style="width: 80px;text-align: center" :color="i.result === 2 ? '#32CD32' : i.result===3 ? '#ff0000' : '#909399'">{{ approval_result[i.result] }}</a-tag>
                    <a-tooltip placement="right">
                      <template slot="title" v-if="i.remark.length > 20">
                        {{ i.remark }}
                      </template>
                      <span class="timeline_remark">备注：{{ i.remark }}</span>
                    </a-tooltip>
                    <span style="width: 200px; display: inline-block;text-align: center">{{ i.last_update }}</span>
                  </div>
                </a-card>
              </div>
              <a-timeline :reverse="true" style="margin-left: 100px;margin-top: 20px;">
                <a-timeline-item :color="changeItemColor(item)" v-for="(item,key) in user_logs" :key="key" :value="item">
                  <a-card :title="getTitle(user_logs.length - key)">
                    <div
                      :bordered="false"
                      style="margin-top: 20px;"
                      :value="i"
                      v-for="(i, index) in item.salesAllList"
                      :key="i.id">
                      <a-divider type="horizontal" v-if="index > 0" />
                      <div style="padding-top: 10px;padding-bottom: 10px;padding-left: 10px;">
                        <a style="width: 100px; display: inline-block; text-align: left;">{{ i.user_nickname }}</a>
                        <a-tag style="width: 80px;text-align: center" :color="i.result === 2 ? '#32CD32' : i.result===3 ? '#ff0000' : '#909399'">{{ approval_result[i.result] }}</a-tag>
                        <a-tooltip placement="right">
                          <template slot="title" v-if="i.remark.length > 20">
                            {{ i.remark }}
                          </template>
                          <span class="timeline_remark">备注：{{ i.remark }}</span>
                        </a-tooltip>
                        <span style="width: 200px; display: inline-block;text-align: center">{{ i.last_update }}</span>
                      </div>
                    </div>
                  </a-card>
                </a-timeline-item>
              </a-timeline>

            </div>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
  </a-card>

</template>

<script>
import { getProcessInstanceInfo, doApproveToDo } from '@/api/design'
import FormRender from '../../common/form/FormRender'
// import { flatFormItem } from '../form'
import ProcessDiagramViewer from '../../admin/layout/ProcessDiagramViewer'
// import ProcessForm from './ProcessForm'

export default {
  components: { FormRender, ProcessDiagramViewer },
  data () {
    return {
      processInstanceId: '',
      type: '',
      spinning: false,
      approval_form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: null,
      formData: {},
      currentNode: {},
      processInfo: '',
      user_logs: [],
      approval_result_status: [],
      approval_result: [],
      node_current_user: [],
      result: 0,
      columns: [
        {
          title: '序号',
          width: 70,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '操作人',
          dataIndex: 'user_nickname',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'is_completed',
          ellipsis: true,
          scopedSlots: { customRender: 'tags' }
        },
        {
          title: '结果',
          dataIndex: 'result',
          ellipsis: true,
          scopedSlots: { customRender: 'result' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '时间',
          dataIndex: 'last_update',
          ellipsis: true
        }
      ]
    }
  },
  computed: {
    forms () {
      return this.$store.state.design.formItems
    }
  },
  methods: {
    getProcessInfo () {
      this.spinning = true
      getProcessInstanceInfo(this.processInstanceId).then(rsp => {
        console.log('流程详情', rsp.data)
        const form = rsp.data.template

        if (form) {
          if (form.settings) {
            form.settings = JSON.parse(form.settings)
          }
          if (form.process) {
            form.process = JSON.parse(form.process)
          }
          this.$store.state.shenpi.design = form
        }
        this.$store.state.shenpi.endList = rsp.data.end_list
        if (rsp.data.node && rsp.data.node.node_id) {
          this.$store.state.shenpi.currentNode = rsp.data.node
        }
        const user_log = rsp.data.user_logs
        this.user_logs = this.generateGroup(user_log)
        this.node_current_user = rsp.data.node_current_user
        this.form = form
        this.spinning = false
      })
    },
    generateGroup (salesAllList) {
      // 后端返回数据list集合
      // 临时记录根据某字段分组的临时键值对变量，用来记录是否已经存在该字段
      const map = {}
      // 存储处理数据完成后的 数组
      this.salesAllList = []
      // 遍历list数据
      for (let i = 0; i < salesAllList.length; i++) {
        // ii代表list中的某一项
        const ii = salesAllList[i]
        if (!map[ii.result_node_id]) {
          // 临时键值对变量中不存在此键
          // 将此数据以对象格式放到数组中{key : value}
          this.salesAllList.push({
            result_node_id: ii.result_node_id,
            salesAllList: [ii]
          })
          // 将未加入map的key放入，这样下次map[ii.type]就会返回true，map只用于此。
          map[ii.result_node_id] = ii
        } else {
          // 如果此键值已存在
          // 遍历数组
          for (let j = 0; j < this.salesAllList.length; j++) {
            // 数组中某一项数据
            const jj = this.salesAllList[j]
            // 如果数据中此key的值和该salesAllList[i].type的值相等
            if (jj.result_node_id === ii.result_node_id) {
              // 把salesAllList[i]也放入该key的value中
              jj.salesAllList.push(ii)
              break
            }
          }
        }
      }
      this.salesAllList = this.salesAllList.map(item => {
        item.salesAllList.sort((a, b) => {
          return Date.parse(a.last_update) - Date.parse(b.last_update)
        })
        if (item.salesAllList.findIndex(target => target.result === 2) !== -1) {
          item.result = 2
        } else {
          item.result = 1
        }
        return item
      })
      // console.log('============', this.salesAllList)
      return this.salesAllList
    },
    changeItemColor (record) {
      return record.result === 2 ? '#32CD32' : record.result === 3 ? '#ff0000' : '#909399'
    },
    linePosition (index) {
      console.log(index % 2)
      return index % 2 === 0 ? 'left' : 'right'
    },
    getTitle (index) {
      return '第' + index + '节点'
    },
    resultChange (value) {
      console.log(value)
      this.result = value.target.value
    },
    doApproval () {
      // 提交
      this.approval_form.validateFields((errors, values) => {
        if (!errors) {
          const data = values
          console.log(values)
          doApproveToDo(this.node_current_user[0].id, data).then((res) => {
            console.log(res, '审批结果')
            this.$router.go(-1)
          })
        }
      })
    }
  },
  created () {
    this.processInstanceId = this.$route.params.id
    this.type = this.$route.query.type
    this.approval_result_status = this.$Dictionaries.approval_result_status
    this.approval_result = this.$Dictionaries.approval_result
  },
  mounted () {
    this.getProcessInfo()
  }
}
</script>
<style scoped>
  .timeline_remark {
    width: 200px;
    margin-left:20px;
    vertical-align: bottom;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
